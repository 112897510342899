/**
 * @fileoverview robot store window for robot subscription
 */

import React, { useEffect, useState } from "react";
import useChange from "../../hooks/useChange";
import "./myRobot.css";
import { Typography } from "antd";
import {
  No_Subscribe_Robot,
} from "../../constants/messages";
import {
  cancelRobotSubscriptionApi,
  postBrokerAccountApi,
  getMySubRobotRating,
  postRobotRating,
  getAllMySubRobotList,
  downloadFile,
} from "../../actions/mySubRobotAction";
import { connect } from "react-redux";
import Loader from "../../components/Loader/Loader";
import NewsubRobotCard from "./NewsubRobotCard";
import CustomPagination from "../CustomPagination/CustomPagination";

function MySubRobot(props) {
  const [entry, entryUpdated] = useChange();
  const [subdata, setsubdata] = useState([]);
  const [subRobotList, setSubRobotList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [apiloading, setapiLoading] = useState(false);
  const { Text } = Typography;

  useEffect(() => {
    window.scrollTo(0, 0);
    const { accessToken } = props;
    setapiLoading(true);
    props.getAllMySubRobotList({
      accessToken: accessToken,
      callback: (resp, data) => {
        setapiLoading(false);
      },
    });
  }, [entry]);

  useEffect(() => {
    const { allMySubRobotList } = props;
    if (allMySubRobotList) { 
      setsubdata(Object.values(allMySubRobotList));
      setLoading(true);
    }
  }, [props.allMySubRobotList]);

  const loderstyle = {
    display: "flex",
    justifyContent: "center",
    height: "50vh",
    alignItems: "center",
  };
  return (
    <div className="robotrent_main mysubrobot fill-container-height ">
      {apiloading ? (
        <div style={loderstyle}>
          <Loader />{" "}
        </div>
      ) : (
        <>
          {loading && subRobotList.length > 0 && (
            <div className="">
                <div className="sub_card_main">
                  {subRobotList &&
                    subRobotList.map((card, i) => (
                      <NewsubRobotCard {...props} key={i} history={props.history} buttonStatus={true} item={card} parentUpdate={entryUpdated} />
          ))}
                </div>
              {/* <Carousel
                history={props.history}
                list={subRobotList}
                ItemComp={CardContainer}
                buttonStatus={true}
                // imageSize={BIG}
                // chunkSize={3}
                imageSize={isBelow1200px ? NORMAL : BIG}
                chunkSize={xxl ? 3 : isBelow1200px ? (isBelow992px ? 1 : 2) : 2}
                parentUpdate={entryUpdated}
                ItemCard={MySubRobotCard}
                {...props}
              />   */}
            </div>
          )}

          {loading && subdata.length <= 0 && (
            <div className="centerContent padding-20 margin_TB">
              <Text strong>{No_Subscribe_Robot}</Text>
            </div>
          )}
        </>
      )}
      {
        subdata.length > 0 &&
      <CustomPagination record={subdata} setDisplayData={setSubRobotList} />
      }
    </div>
    
  );
}

const mapDispatchToProps = {
  cancelRobotSubscriptionApi: cancelRobotSubscriptionApi,
  postBrokerAccountApi: postBrokerAccountApi,
  getMySubRobotRating: getMySubRobotRating,
  postRobotRating: postRobotRating,
  getAllMySubRobotList: getAllMySubRobotList,
  downloadFile: downloadFile,
};
const mapStateToProps = ({
  mySubRobotReducer: { allMySubRobotData, allMySubRobotList },
  userReducer: { accessToken, userData },
}) => ({
  allMySubRobotData,
  accessToken,
  allMySubRobotList,
  userData,
});

export default connect(mapStateToProps, mapDispatchToProps)(MySubRobot);
