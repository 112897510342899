/**
 * @fileoverview Component for Indicator Drawer
 */
import "./indicatorDrawer.css";

import { COMMON_TEXT, STEP } from "../../../constants/constants.string";
import { Checkbox, Form, Input, Popover, Select, Tooltip } from "antd";
import {
  INDICATOR_DATATYPES,
  INDICATOR_DRAWER_STATE,
} from "../../../constants/constants.Indicator";
import React, { useEffect, useState } from "react";

import { DEFAULT_INDICATOR_STATE } from "../../../constants/constants";
import {
  HELP_TEXT,
  POPOVER_USER_ADJUSTABLE,
} from "../../../constants/constants.popover";
import { QuestionCircleOutlined } from "@ant-design/icons";
import UpdateButton from "./updateButton";
import { isEmpty } from "../../../utils/utils";

const { Option } = Select;

export default function IndicatorDrawer(props) {
  const { indicatorType, code, onCodeChange, setDrawerState } = props;

  // sets parameters corresponding to filter type
  const [codeBlock, setCodeBlock] = useState({});
  const [codeBlockError, setCodeBlockError] = useState({});
  const [popOverContent, setPopOverContent] = useState({});
  const [activefile, setactivefile] = useState("mq4");

  useEffect(() => {
    setCodeBlockError({});
    if (!isEmpty(code)) {
      if (code[indicatorType]) {
        onCodeChange({ ...code[indicatorType] });
        setCodeBlock({ ...code[indicatorType] });
      } else {
        indicatorType !== code["name"]
          ? setCodeBlock({ ...DEFAULT_INDICATOR_STATE[indicatorType] })
          : setCodeBlock({ ...code });
      }
    }
    setPopOverContent({ ...HELP_TEXT[indicatorType] });
  }, [indicatorType, code]);

  // validation states for empty field check
  const [isValid, setIsValid] = useState(true);
  useEffect(() => {
    if (Object.keys(codeBlockError).length) {
      const isValid = !Boolean(
        Object.values(codeBlockError).filter((i) => i.status).length
      );
      setIsValid(isValid);
    }
  }, [codeBlockError]);

  /**
   * @param {string} value value of the field
   * @param {string} fieldName name of the field
   * @description handles change in fields
   */
  const handleFieldChange = (value, fieldName, isFieldRequired = false) => {
    let tempCodeBlock = JSON.parse(JSON.stringify(codeBlock));
    tempCodeBlock[fieldName]["VALUE"] = value;
    setCodeBlock({ ...tempCodeBlock });
    setCodeBlockError({
      ...codeBlockError,
      [fieldName]: {
        status: value || value === 0 || !isFieldRequired ? false : true,
        help:
          value || value === 0 || !isFieldRequired
            ? null
            : COMMON_TEXT.EMPTY_STRING,
      },
    });
  };

  /**
   * @param {boolean} isChecked is user-adjustable
   * @param {string} fieldName name of the field
   * @description handles if parameter is user-adjustable
   */
  const handleCheckChange = (isChecked, fieldName) => {
    let tempCodeBlock = JSON.parse(JSON.stringify(codeBlock));
    tempCodeBlock[fieldName]["ADJUSTABLE"] = isChecked;
    setCodeBlock(tempCodeBlock);
  };

  /**
   * @description on Update button clicked
   */
  const handleOnUpdateClick = () => {
    if (isValid) {
      onCodeChange({ ...codeBlock });
      setDrawerState(false);
    }
  };

  const ignoreformq4 = () => {
    const removeForce = ["Applied Volume"];
    const ignore_list = {
      "Force Index": removeForce,
      "Market Facilitation Index": removeForce,
      "Money Flow Index": removeForce,
      "On Balance Volume": removeForce,
      "Accumulation/Distribution": removeForce,
    };
    const removeFields = ignore_list[indicatorType];

    const fields = INDICATOR_DRAWER_STATE[indicatorType];
    const mq4_field = fields?.filter( (field) =>  !removeFields?.includes(field.NAME)  );
    return mq4_field
  };
  const ignoreformq5 = () => {
    const commanRemoveFields = [""];
    const adx_ignore = ["Applied Price"];
    const iBearsPower = ["Applied Price"];
    const ma_ignore = [""];
    const Shift = ["Shift"];
    const rvi = ["Shift"];
    const STOCHASTIC = ["Shift"];

    const ignore_list = {
      "ADX": adx_ignore,
      "Moving Average": ma_ignore,
      "Bears Power": iBearsPower,
      "Bulls Power": iBearsPower,
      "Force Index": iBearsPower,
      "On Balance Volume": iBearsPower,
      "Accumulation/Distribution": Shift,
      "Parabolic SAR": Shift,
      "Relative Vigor Index": rvi,
      "Stochastic Oscillator": STOCHASTIC,
    };

    const removeFields = ignore_list[indicatorType];

    const fields = INDICATOR_DRAWER_STATE[indicatorType];
    const mq5_field = fields?.filter(
      (field) =>
        !commanRemoveFields.includes(field.NAME) &&
        !removeFields?.includes(field.NAME)
    );
    return mq5_field;
  };
  const mq5_field = ignoreformq5();
  const fields = ignoreformq4();



  return (
    <div className="drawer-body">
      <div className="property-header">{COMMON_TEXT.PROPERTIES}</div>
      <div className="property-body">
        <div className="drawer_btn">
          <button
            type="button"
            onClick={() => setactivefile("mq4")}
            className={`ant-btn button default-button margin-right-10  b-5 ant-btn-default ${
              activefile === "mq4" ? "btn_hover" : ""
            }`}
          >
            mq4
          </button>
          <button
            type="button"
            onClick={() => setactivefile("mq5")}
            className={`ant-btn button default-button margin-right-10  b-5 ant-btn-default ${
              activefile === "mq5" ? "btn_hover" : ""
            }`}
          >
            mq5
          </button>
        </div>
        <Form>
          {fields &&
            (activefile === "mq5" ? mq5_field : fields).map((field) => {
              if (field.DATATYPE === INDICATOR_DATATYPES.ENUM) {
                return (
                  codeBlock[field.NAME] && (
                    <SelectField
                      key={field.NAME}
                      label={field.NAME}
                      data={field.DATA}
                      checked={codeBlock[field.NAME].ADJUSTABLE}
                      onCheckChange={(event) =>
                        handleCheckChange(event.target.checked, field.NAME)
                      }
                      value={codeBlock[field.NAME].VALUE}
                      help={
                        codeBlockError[field.NAME] &&
                        codeBlockError[field.NAME].help
                      }
                      error={
                        codeBlockError[field.NAME] &&
                        codeBlockError[field.NAME].status
                      }
                      onChange={(key) => handleFieldChange(key, field.NAME)}
                      content={popOverContent[field.NAME]}
                    />
                  )
                );
              }

              if (field.DATATYPE === INDICATOR_DATATYPES.STRING) {
                return (
                  codeBlock[field.NAME] && (
                    <InputField
                      key={field.NAME}
                      label={field.NAME}
                      content={popOverContent[field.NAME]}
                      checked={codeBlock[field.NAME].ADJUSTABLE}
                      value={codeBlock[field.NAME].VALUE}
                      help={
                        codeBlockError[field.NAME] &&
                        codeBlockError[field.NAME].help
                      }
                      error={
                        codeBlockError[field.NAME] &&
                        codeBlockError[field.NAME].status
                      }
                      onChange={(event) =>
                        handleFieldChange(
                          event.target.value,
                          field.NAME,
                          field.REQUIRED
                        )
                      }
                      onCheckChange={(event) =>
                        handleCheckChange(event.target.checked, field.NAME)
                      }
                    />
                  )
                );
              }

              if (
                field.DATATYPE === INDICATOR_DATATYPES.INTEGER ||
                field.DATATYPE === INDICATOR_DATATYPES.DOUBLE
              ) {
                return (
                  codeBlock[field.NAME] && (
                    <InputField
                      key={field.NAME}
                      label={field.NAME}
                      content={popOverContent[field.NAME]}
                      checked={codeBlock[field.NAME].ADJUSTABLE}
                      onCheckChange={(event) =>
                        handleCheckChange(event.target.checked, field.NAME)
                      }
                      value={codeBlock[field.NAME].VALUE}
                      step={
                        field.DATATYPE === INDICATOR_DATATYPES.INTEGER
                          ? STEP.INTEGER
                          : STEP.FLOAT
                      }
                      typeOfField={
                        field.DATATYPE !== INDICATOR_DATATYPES.STRING &&
                        "number"
                      }
                      help={
                        codeBlockError[field.NAME] &&
                        codeBlockError[field.NAME].help
                      }
                      error={
                        codeBlockError[field.NAME] &&
                        codeBlockError[field.NAME].status
                      }
                      integerOnly={
                        field.DATATYPE === INDICATOR_DATATYPES.INTEGER
                      }
                      onChange={(event) =>
                        handleFieldChange(
                          event.target.value,
                          field.NAME,
                          field.REQUIRED
                        )
                      }
                    />
                  )
                );
              }
            })}
        </Form>
      </div>
      <UpdateButton onUpdate={handleOnUpdateClick} />
    </div>
  );
}

/**
 * @description Select Field Component for data of type ENUM
 */
export function SelectField(props) {
  return (
    <div className="option-style" id="indicator-block">
      <div className="indicator-field">
        <div className="indicator-label" style={{marginBottom:props?.hide && '12px'}}>{props.label}</div>
        {
          !props?.hide &&
          <>
        <Popover content={props.content && props.content}>
        <img style={{height:'15px',maxWidth:'100%'}} src={require('../../../assets/robotasset/message-question.png')} alt="" />
        </Popover>
        {typeof props.checked !== "undefined" && (
          <Tooltip placement="right" title={POPOVER_USER_ADJUSTABLE}>
            <Checkbox
              className="indicator-checkbox"
              checked={props.checked}
              onChange={props.onCheckChange}
            />
          </Tooltip>
        )}

          </>
        }
      </div>
      <Form.Item
        className="indicator-form-item"
        help={props.help && props.help}
        hasFeedback={props.error}
      >
        <Select
          placeholder="Select"
          className="theme-text indicator-select"
          onChange={props.onChange}
          showArrow={true}
          value={props.value}
        >
          {Object.keys(props.data).map((item, index) => (
            <Option key={`${item}${index}`} value={props.data[item]}>
              {item}
            </Option>
          ))}
        </Select>
      </Form.Item>
    </div>
  );
}

/**
 * @description Input Field Component for data of type Integer, String, Float/Double
 */
export function InputField(props) {
  return (
    <div className="option-style" id="indicator-block">
      <div className="indicator-field">
        <div className="indicator-label" style={{marginBottom:props?.hide && '12px'}}>{props.label}</div>
        {
          !props?.hide &&
          <>
        <Popover content={props.content && props.content}>
          <img style={{height:'15px',maxWidth:'100%'}} src={require('../../../assets/robotasset/message-question.png')} alt="" />
        </Popover>
        {typeof props.checked !== "undefined" && (
          <Tooltip placement="right" title={POPOVER_USER_ADJUSTABLE}>
            <Checkbox
              className="indicator-checkbox"
              checked={props.checked}
              onChange={props.onCheckChange}
            />
          </Tooltip>
        )}
          </>
        }
      </div>
      <Form.Item
        className="indicator-form-item"
        validateStatus={props.error ? "error" : "success"}
        help={props.help && props.help}
      >
        <Input
          placeholder={props.label}
          type={props.typeOfField ? props.typeOfField : ""}
          value={props.value}
          step={props.step}
          onChange={props.onChange}
          onKeyDown={(event) =>
            props.integerOnly && event.key === "." && event.preventDefault()
          }
        />
      </Form.Item>
    </div>
  );
}
