import "./profile.css";

import {
  ALL_FILTERS,
  BRONZE,
  BRONZE_CONTENT,
  CANCEL,
  CANCELLED_SUBSCRIPTION_CONTENT1,
  CANCELLED_SUBSCRIPTION_CONTENT2,
  CANCEL_SUBSCRIPTION,
  CANCEL_SUBSCRIPTION_BUTTON,
  CANCEL_SUBSCRIPTION_CONTENT1,
  CANCEL_SUBSCRIPTION_CONTENT2,
  DATE_FORMAT,
  DOWNGRADE,
  DOWNGRADES,
  DOWNLOAD_EX4,
  DOWNLOAD_MQ4,
  EMAIL_CHANGED,
  EMAIL_REQUIRED,
  ERROR,
  GOLD,
  LIMITED_FILTERS,
  MODAL_HEADER_CANCEL_SUBSCRIPTION,
  MODAL_HEADER_CURRENT_BILLING_CYCLE,
  MODAL_HEADER_PAYMENT_SYNC_ERROR,
  MODAL_HEADER_PAYMENT_UPDATE,
  PLAN_SUBSCRIPTION_ERROR,
  PROCEED,
  SILVER,
  SILVER_CONTENT,
  SUCCESS,
  THEME_COLOR,
  UPGRADE,
  UPGRADE_OR,
  UPGRADE_TO_BRONZE,
} from "../../constants/messages";
import { plan, PLANS } from "../../constants/plans";
import {
  Button,
  Card,
  Input,
  Modal,
  Spin,
  Tag,
  message,
  Steps,
  Divider,
  Alert,
} from "antd";
import React, { useState } from "react";

import { EMAIL_VALIDATION, FREE_TRAIL } from "../../constants/constants";
import { EditOutlined } from "@ant-design/icons";
import PaymentMethod from "../payment/paymentMethod";
// import PaymentType from '../payment/paymentType';
import Ptypebackup from "../payment/Ptypebackup";
import { cancelSubscriptionApi } from "../../actions/paymentActions";
import { changeUserEmail } from "../../actions/commonActions";
import { connect } from "react-redux";
import { getPlans } from "../../actions/paymentActions";
import moment from "moment";

function higherPlan(currentPlan, plans) {
  let found = false;
  const higherplans = [];
  const lowerplans = [];
  for (let i = 0; i < plans.length; i++) {
    if (found) {
      higherplans.push(plans[i].payment_type);
    }
    if (plans[i].payment_type === currentPlan) {
      found = true;
    }
    if (!found && plans[i].price !== 0) {
      lowerplans.push(plans[i].payment_type);
    }
  }
  return { higher: higherplans, lower: lowerplans };
}

function Profile(props) {
  const [emailEdit, setEmailEdit] = useState(false);
  const [email, setEmail] = useState(
    props.userData && props.userData.email ? props.userData.email : ""
  );
  const [planPopUp, setPlanPopUp] = useState(false);
  const [err, setErr] = useState(false);
  const [errMsg, setErrMsg] = useState(true);
  const [showPaymentOptions, setShowPaymentOptions] = useState(false);
  const [planSyncError, setPlanSyncError] = useState(false);
  const [infoModal, setInfoModal] = useState(false);
  const [cancelModal, setCancelModal] = useState(false);
  const [selectedPrice, setSelectedPrice] = useState(false);
  const [selectedType, setSelectedType] = useState(false);
  const [is_coupon_used, setis_coupon_used] = useState(
    JSON.parse(localStorage.getItem("is_coupon_used"))
  );

  const cancelClick = () => {
    setCancelModal(false);
    props.cancelSubscriptionApi({
      accessToken: props.accessToken,
      callback: (data) => {
        if (data === SUCCESS) message.success(CANCEL_SUBSCRIPTION);
      },
    });
  };

  const onEmailEdit = () => {
    setEmailEdit(!emailEdit);
  };

  const handleChange = (value) => {
    setErr(false);
    const val = value.target.value;
    setEmail(val);
    var patt = new RegExp(EMAIL_VALIDATION);
    if (!patt.test(val)) {
      setErr(true);
      setErrMsg(EMAIL_REQUIRED);
    }
  };

  const onEmailUpdate = () => {
    props.changeUserEmail({
      accessToken: props.accessToken,
      data: {
        current_email: props.userData.email.toLowerCase().trim(),
        new_email: email.toLowerCase().trim(),
      },
      callback: (res, data) => {
        if (res === ERROR) {
          console.log(data);
          if (data.message !== "Token is Expired") {
            setErr(true);
            setErrMsg(data.message);
          }
        }

        if (res === SUCCESS) {
          setEmailEdit(!emailEdit);
          message.success(EMAIL_CHANGED);
        }
      },
    });
  };

  const onSubmit = (type, price) => {
    setInfoModal(false);
    setPlanPopUp(false);
    setSelectedPrice(price);
    setSelectedType(type);
    setShowPaymentOptions(true);
  };

  const paymentCancel = () => {
    setInfoModal(false);
    setPlanPopUp(false);
    setShowPaymentOptions(false);
  };

  const onComplete = () => {
    setShowPaymentOptions(false);
  };

  const proceedPayment = () => {
    setInfoModal(false);
    props.getPlans({
      callback: (res) => {
        if (res === ERROR) {
          setPlanSyncError(true);
        } else {
          setPlanPopUp(true);
        }
      },
    });
  };

  const { plans, payment, userData, subscription } = props;
  // console.log(payment, "--------planms-------");

  let currentPlan = !payment.hasOwnProperty("mode")
    ? props.subscription
      ? "free"
      : null
    : payment.mode;

  const isUpgrade = currentPlan !== SILVER;
  const available_plans =
    plans.length > 0 && new Map(plans.map((k) => [k.payment_type, k]));
  const plans_rates =
    plans.length > 0 && plans.sort((a, b) => (a.price > b.price ? 1 : -1));
  let upgradePlan = higherPlan(currentPlan, plans_rates || []);

  return (
    <div className="profile-container">
      <div className="">
        <Card
          bodyStyle={{ fontSize: "16px" }}
          style={{ textTransform: "capitalize" }}
          headStyle={{ color: THEME_COLOR, fontWeight: "bold" }}
          className="profile_cards"
        >
          <div className="first_step_card">
            <h2>
              {subscription === FREE_TRAIL
                ? "Free member"
                : `${
                    props.subscription
                      ? currentPlan === BRONZE
                        ? "NanoBot"
                        : currentPlan === GOLD
                        ? "MegaTron"
                        : "RoboMate"
                      : "Free"
                  } member since ${moment(props.payment.subscribed_date).format(
                    DATE_FORMAT
                  )}`}
            </h2>
            <span>Subscription Plan Information</span>
            <div className="inputField">
              <div className="left-part">
                <h6>Name</h6>
                <div className="fields">
                  <img
                    src={require("../../assets/robotasset/Profile.png")}
                    alt=""
                  />
                  <Input
                    disabled={!emailEdit}
                    onChange={handleChange}
                    value={props.userData.name}
                  />
                </div>
              </div>
              <div className="right-part">
                <div className="">
                  <h6>Email Address</h6>
                  <div className="fields">
                    <img
                      src={require("../../assets/robotasset/Message.png")}
                      alt=""
                    />
                    <Input
                      disabled={!emailEdit}
                      onChange={handleChange}
                      value={email}
                    />
                  </div>
                </div>
                {emailEdit ? (
                  <Button
                    type="primary"
                    className="button primary-button b-5"
                    onClick={onEmailUpdate}
                    disabled={
                      email.toLowerCase().trim() ===
                        props.userData.email.toLowerCase().trim() || err
                    }
                    style={{ marginTop: "20px" }}
                  >
                    Update
                  </Button>
                ) : (
                  <img
                    style={{ marginTop: "20px" }}
                    onClick={onEmailEdit}
                    src={require("../../assets/robotasset/sms-edit.png")}
                    alt=""
                  />
                )}
              </div>
            </div>
            {err && (
              <p
                className="error-message small-text"
                style={{ textAlign: "center" }}
              >
                {errMsg}
              </p>
            )}

            {subscription === FREE_TRAIL ? (
              <>
                <div className="rs_days">
                  <img
                    src={require("../../assets/robotasset/time.png")}
                    alt=""
                    style={{ height: "25px" }}
                  />
                  Remaining free trial days :
                  <b> {userData.remaining_days} Days</b>
                </div>
              </>
            ) : (
              <>
                <div className="calen">
                  <img
                    src={require("../../assets/robotasset/Calendar.png")}
                    alt=""
                    style={{ height: "25px" }}
                  />
                  {MODAL_HEADER_CURRENT_BILLING_CYCLE}
                  <b>
                    {moment(props.payment.start_date).format(DATE_FORMAT)} To{" "}
                    {moment(props.payment.end_date).format(DATE_FORMAT)}
                  </b>
                </div>
                <div className="rs_days">
                  <img
                    src={require("../../assets/robotasset/time.png")}
                    alt=""
                    style={{ height: "25px" }}
                  />
                  Remaining days from current billing cycle :
                  <b> {props.payment.remaining_days} Days</b>
                </div>
              </>
            )}
          </div>
          <div>
            {props.subscription && (
              <div>
                {currentPlan === BRONZE && (
                  <Button
                    type="primary"
                    className="btn b-5 full-width margin-10 height-50"
                    onClick={setInfoModal}
                  >
                    {UPGRADE}
                  </Button>
                )}
                {currentPlan === SILVER && (
                  <Button
                    type="primary"
                    className="btn b-5 full-width margin-10 height-50"
                    onClick={setInfoModal}
                  >
                    {UPGRADE_OR}
                  </Button>
                )}
                {currentPlan === GOLD && (
                  <Button
                    type="primary"
                    className="btn b-5 full-width margin-10 height-50"
                    onClick={setInfoModal}
                  >
                    {DOWNGRADES}
                  </Button>
                )}
                {currentPlan === "free" && (
                  <Button
                    type="primary"
                    className="btn b-5 full-width margin-10 height-50"
                    onClick={setInfoModal}
                  >
                    {UPGRADE_TO_BRONZE}
                  </Button>
                )}
                {currentPlan !== "free" && (
                  <Button
                    type="default"
                    // disabled={is_coupon_used}
                    style={{ marginTop: "15px" }}
                    className="button full-width default-button height-50"
                    onClick={() => setCancelModal(true)}
                  >
                    {CANCEL_SUBSCRIPTION_BUTTON}
                  </Button>
                )}
              </div>
            )}
            {!props.subscription && (
              <div>
                <Tag
                  className="full-width"
                  style={{ marginTop: "10px" }}
                  color="red"
                >
                  <div>{CANCELLED_SUBSCRIPTION_CONTENT1}</div>
                </Tag>
                <Button
                  type="primary"
                  className="full-width margin-10 height-50"
                  onClick={setInfoModal}
                >
                  Subscribe Again
                </Button>
              </div>
            )}
          </div>
        </Card>
        {/* <Card
          title={subscription===FREE_TRAIL? 'Free member' :`${
            props.subscription ? currentPlan : "Free" } member since ${moment(props.payment.subscribed_date).format(DATE_FORMAT )}`}
          bodyStyle={{ fontSize: "16px" }}
          style={{ width: 500, textTransform: "capitalize" }}
          headStyle={{ color: THEME_COLOR, fontWeight: "bold" }}
          className="profile_cards"
        >
          <p className="theme-text">
            <span className="label">Name: </span>
            {props.userData.name}
          </p>
          <p className="theme-text">
            <span className="label">Email: </span>
            <Input
              disabled={!emailEdit}
              onChange={handleChange}
              value={email}
              style={{
                width: "60%",
                border: "1px solid #b1a1c3",
                borderRadius: "5px",
              }}
            />

            {emailEdit ? (
              <Button
                type="primary"
                className="button primary-button b-5"
                onClick={onEmailUpdate}
                disabled={
                  email.toLowerCase().trim() ===
                    props.userData.email.toLowerCase().trim() || err
                }
                style={{ margin: "0px 10px" }}
              >
                Update
              </Button>
            ) : (
              <EditOutlined
                onClick={onEmailEdit}
                style={{ padding: "0px 10px", fontSize: "20px" }}
              />
            )}
          </p>
          {err && <p className="error-message small-text">{errMsg}</p>}
          <Tag style={{ width: "100%" ,marginTop:'15px'}} color="warning">
            {subscription===FREE_TRAIL
            ?
            <div>
            <b>Remaining free trial days :</b>{" "}
            {userData.remaining_days} Days
          </div>
          :
            <>
              <div>
                <b>{MODAL_HEADER_CURRENT_BILLING_CYCLE}</b>{" "}
                {moment(props.payment.start_date).format(DATE_FORMAT)} To{" "}
                {moment(props.payment.end_date).format(DATE_FORMAT)}
              </div>
              <div>
                <b>Remaining days from current billing cycle :</b>{" "}
                {props.payment.remaining_days} Days
              </div>
            </>
          }
          </Tag>
          <div>
            {props.subscription && (
              <div>
                {currentPlan === BRONZE && (
                  <Button
                    type="primary"
                    className="btn b-5 full-width margin-10 height-50"
                    onClick={setInfoModal}
                  >
                    {UPGRADE}
                  </Button>
                )}
                {currentPlan === "silver" && (
                  <Button
                    type="primary"
                    className="btn b-5 full-width margin-10 height-50"
                    onClick={setInfoModal}
                  >
                    {DOWNGRADE}
                  </Button>
                )}
                {currentPlan === "free" && (
                  <Button
                    type="primary"
                    className="btn b-5 full-width margin-10 height-50"
                    onClick={setInfoModal}
                  >
                    {UPGRADE_TO_BRONZE}
                  </Button>
                )}
                {currentPlan !== "free" && (
                  <Button
                    type="default"
                    disabled={is_coupon_used}
                    className="button full-width default-button height-50"
                    onClick={() => setCancelModal(true)}
                  >
                    {CANCEL_SUBSCRIPTION_BUTTON}
                  </Button>
                )}
              </div>
            )}
            {!props.subscription && (
              <div>
                <Tag
                  className="full-width"
                  style={{ marginTop: "10px" }}
                  color="red"
                >
                  <div>{CANCELLED_SUBSCRIPTION_CONTENT1}</div>
                </Tag>
                <Button
                  type="primary"
                  className="full-width margin-10 height-50"
                  onClick={setInfoModal}
                >
                  Subscribe Again
                </Button>
              </div>
            )}
          </div>
        </Card> */}
      </div>
      <Modal
        title={MODAL_HEADER_PAYMENT_UPDATE}
        visible={planPopUp || showPaymentOptions}
        style={{ top: "15%" }}
        width={"75%"}
        closable={true}
        onCancel={paymentCancel}
        footer={null}
      >
        <div className="full-width">
          {available_plans && planPopUp && !props.subscription && (
            <>
              <Ptypebackup
                history={props.history}
                type={payment.mode}
                onSubmit={onSubmit}
                styleClassname="upgrade"
                content={PLANS[payment.mode]?.no_sub_upgrade_msg}
                price={available_plans.get(payment.mode)?.price}
                options={PLANS[payment.mode].options}
              />
            </>
          )}
          {available_plans && planPopUp && props.subscription && (
            <div className="flex-row" style={{ justifyContent: "center" }}>
              {upgradePlan.higher.map((p) => {
                return (
                  <Ptypebackup
                    key={p}
                    history={props.history}
                    type={p}
                    onSubmit={onSubmit}
                    content={BRONZE_CONTENT}
                    price={available_plans.get(p).price}
                    options={PLANS[p].options}
                    styleClassname="upgrade"
                  />
                );
              })}
              {upgradePlan.lower.map((p) => {
                return (
                  <Ptypebackup
                    key={p}
                    history={props.history}
                    type={p}
                    onSubmit={onSubmit}
                    content={SILVER_CONTENT}
                    price={available_plans.get(p).price}
                    options={PLANS[p].options}
                    styleClassname="upgrade"
                  />
                );
              })}
            </div>
          )}
          {showPaymentOptions && (
            <Spin
              tip="Loading..."
              size="large"
              spinning={props.fetchingPayment}
              className="spinner"
            >
              <PaymentMethod
                styleClassname="upgrade"
                action={props.subscription ? "update" : "resubscribe"}
                onComplete={onComplete}
                selectedPrice={selectedPrice}
                selectedType={selectedType}
                setis_coupon_used={setis_coupon_used}
              />
            </Spin>
          )}
        </div>
      </Modal>
      <Modal
        closable={true}
        title={
          subscription === FREE_TRAIL
            ? "Free member"
            : `${MODAL_HEADER_CURRENT_BILLING_CYCLE} ${moment(
                props.payment.start_date
              ).format(DATE_FORMAT)} To ${moment(props.payment.end_date).format(
                DATE_FORMAT
              )}`
        }
        visible={infoModal}
        bodyStyle={{ color: THEME_COLOR }}
        onCancel={paymentCancel}
        footer={[
          <Button
            key="submit"
            className="btns b-5"
            type="default"
            onClick={paymentCancel}
          >
            {CANCEL}
          </Button>,
          <Button
            key="submitCancel"
            className="btns b-5"
            type="primary"
            onClick={proceedPayment}
          >
            {PROCEED}
          </Button>,
        ]}
      >
        {currentPlan !== plan.free && (
          <>
            <p>You have {props.payment.remaining_days} remaining days.</p>
            <p>
              You will lose the money if you re-subscribe or{" "}
              {isUpgrade ? "upgrade" : "downgrade"}.
            </p>
            <p>
              Please click Proceed to continue or Cancel if you are willing to
              wait.
            </p>
          </>
        )}
        {currentPlan === plan.free && <>Please Proceed </>}
      </Modal>
      <Modal
        closable={true}
        title={MODAL_HEADER_CANCEL_SUBSCRIPTION}
        visible={cancelModal}
        bodyStyle={{ color: THEME_COLOR }}
        onCancel={() => setCancelModal(false)}
        footer={[
          <Button
            key="submit"
            type="default"
            onClick={() => setCancelModal(false)}
          >
            {CANCEL}
          </Button>,
          <Button key="submitCancel" type="primary" onClick={cancelClick}>
            {PROCEED}
          </Button>,
        ]}
      >
        <p>{CANCEL_SUBSCRIPTION_CONTENT1}</p>
        <p>{CANCEL_SUBSCRIPTION_CONTENT2}</p>
      </Modal>
      <Modal
        closable={true}
        title={MODAL_HEADER_PAYMENT_SYNC_ERROR}
        visible={planSyncError}
        bodyStyle={{ color: THEME_COLOR }}
        onCancel={() => setPlanSyncError(false)}
        footer={null}
      >
        <p>{PLAN_SUBSCRIPTION_ERROR}</p>
      </Modal>
    </div>
  );
}

const mapStateToProps = ({
  userReducer: { accessToken, userData, payment, subscription },
  paymentReducer: { plans, fetchingPayment },
}) => ({
  accessToken,
  userData,
  payment,
  subscription,
  plans,
  fetchingPayment,
});

const mapDispatchToProps = {
  cancelSubscriptionApi: cancelSubscriptionApi,
  getPlans: getPlans,
  changeUserEmail: changeUserEmail,
};

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
