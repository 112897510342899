import "./login.css";

import { Button, Form, Input, Modal, message } from "antd";
import {
  CANCEL,
  CODE_VERIFIY,
  CODE_VERIFIY_MESSAGE,
  EMAIL_REQUIRED,
  ERROR,
  FORGOT_PASSWORD,
  LOADER,
  LOGIN,
  PASSWORD_REQUIRED,
  PROCEED,
  SIGN_UP,
  SUCCESS,
  THEME_COLOR,
  USER_NOTFOUND,
  VERIFY_EMAIL,
} from "../../constants/messages";
import React, { useEffect, useState } from "react";
import {
  getQuestions,
  getUser,
  resendCode,
  verifyCode,
} from "../../actions/commonActions";
import logo from "../../assets/robotasset/logo.png";
import { EMAIL_VALIDATION } from "../../constants/constants";
import PaymentMethod from "../payment/paymentMethod";
import PaymentOptions from "../payment/paymentOptions";
import SideBanner from "../commonComponent/sideBanner";
import { connect } from "react-redux";
import { getPlans } from "../../actions/paymentActions";
import WebFont from "webfontloader";
import EmailIcon from "../../assets/robotasset/email.png";
import PassIcon from "../../assets/robotasset/Password.png";
import moment from "moment";
import TermsAndPrivacy from "../Terms&Privacy";

WebFont.load({
  google: {
    families: ["Montserrat:300,400,700", "sans-serif"],
  },
});

const IMF = ({ url }) => {
  return (
    <img src={url} style={{ height: "25px", marginRight: "5px" }} alt="" />
  );
};
function Login(props) {
  const [showPayment, setShowPayment] = useState(false);
  const [showPaymentOptions, setShowPaymentOptions] = useState(false);
  const [paymentPopUp, setPaymentPopUp] = useState("");
  const [hasErrored, setHasErrored] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [selectedType, setSelectedType] = useState("");
  const [selectedPrice, setSelectedPrice] = useState("");
  const [loginData, setLoginData] = useState({});
  const [paymentPopUpContent, setpaymentPopUpContent] = useState("");
  const [code, setcode] = useState("");

  const [resenderror, setresenderror] = useState("");
  const [paymentFailedCheck, setpaymentFailedCheck] = useState("");
  const [codeseterror, setcodeerror] = useState(false);
  const [modalvisible, setmodalvisible] = useState(false);
  const { resendCode, verifyCode, apiloader } = props;
  const [openPrModal, setOpenPrModal] = useState(false);

  const onOnClosePrModal = () => {
    setOpenPrModal(false);
  };

  const callback = (res, data) => {
    if (data.message === CODE_VERIFIY_MESSAGE) {
      setmodalvisible(true);
      setresenderror(data.message);
    } else if (data.detail === USER_NOTFOUND) {
      message.error(data.detail);
    } else if (res === "error") {
      setHasErrored(true);
      setErrorMessage(data.message);
      // if(data.data==="subscriptionFailed" ){
      //   message.error(data.message)
      //   setShowPayment(true);
      //  }else
      if (data.data === "paymentFailed" || data.data === "subscriptionFailed") {
        setShowPayment(true);
        setPaymentPopUp(true);
        setpaymentFailedCheck(true);
        setpaymentPopUpContent(data.message);
      } else if (data.subscription_plan !== "free -> 0.0 USD") {
        // if (data.subscription_plan !== "free") {
        setpaymentFailedCheck(true);
        setShowPayment(true);
        setPaymentPopUp(true);
        setpaymentPopUpContent(data.message);
      }
    } else {
      setHasErrored(true);
      setErrorMessage("Email/Password doesn't match!");
    }
  };

  const onFinish = (values) => {
    const data = { ...values, email: values.email.toLowerCase().trim() };
    setLoginData({ ...data });
    props.getUser({ ...data, callback: callback });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const handleForgotPassword = () => {
    props.history.push("/forgot-password");
  };

  const handleSignUp = () => {
    props.history.push("/sign-up");
  };

  const handleChange = () => {
    if (hasErrored) {
      setHasErrored(false);
    }
  };

  const onSubmit = (type, price, couponCode) => {
    if (couponCode) {
      console.log(couponCode);
      setShowPayment(false);
      setErrorMessage("");
    } else {
      setShowPaymentOptions(true);
      setSelectedPrice(price);
      setSelectedType(type);
    }
  };

  const handleProceed = () => {
    setPaymentPopUp(!paymentPopUp);
  };
  const modaleclos = () => {
    setcodeerror(false);
    setcode("");
    setmodalvisible(false);
  };

  const verifyemail = () => {
    if (code) {
      const data = { email: loginData?.email, verification_code: code };
      verifyCode({
        data: { ...data },
        callback: (resp, data) => {
          if (resp !== ERROR) {
            message.success(data.message);
            setmodalvisible(false);
          } else {
            message.error(data);
          }
        },
      });
    } else {
      setcodeerror(true);
    }
  };

  const handleResendCode = () => {
    const data = { email: loginData?.email };
    resendCode({
      data: { ...data },
      callback: (resp, data) => {
        if (resp === SUCCESS) {
          message.success(data.message);
        } else {
          message.error(data);
        }
      },
    });
  };

  return (
    <div className="signup-container">
      <SideBanner />
      {showPayment && !showPaymentOptions ? (
        <PaymentOptions
          paymentFailedCheck={paymentFailedCheck}
          loginData={loginData}
          paymentPopUpContent={paymentPopUpContent}
          onSubmit={onSubmit}
          history={props.history}
        />
      ) : showPayment && showPaymentOptions ? (
        <PaymentMethod
          history={props.history}
          selectedPrice={selectedPrice}
          selectedType={selectedType}
          loginData={loginData}
          paymentFailedCheck={paymentFailedCheck}
        />
      ) : (
        <>
          <div className="right-body-login center_div">
            <img
              src={require("../../assets/robotasset/logo.png")}
              className="auth_logo"
              alt=""
            />
            <div className="login-box">
              <Form
                name="basic"
                initialValues={{
                  remember: true,
                }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
              >
                <Form.Item className="login_title">
                  <div className="large-text label theme-text text-left ">
                    SIGN IN
                  </div>
                  <p>Enter your email and password to sign in!</p>
                </Form.Item>
                <div className="f_label">EMAIL</div>
                <Form.Item
                  name="email"
                  rules={[
                    { required: true, message: EMAIL_REQUIRED },
                    {
                      pattern: EMAIL_VALIDATION,
                      message: EMAIL_REQUIRED,
                    },
                  ]}
                >
                  <Input
                    prefix={<IMF url={EmailIcon} />}
                    onChange={handleChange}
                    className="b-5 inputBox-style height-50"
                    placeholder="Enter your email address"
                  />
                </Form.Item>
                <div className="f_label">PASSWORD</div>
                <Form.Item
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: PASSWORD_REQUIRED,
                    },
                  ]}
                >
                  <Input.Password
                    prefix={<IMF url={PassIcon} />}
                    onChange={handleChange}
                    className="b-5 inputBox-style height-50"
                    placeholder="Enter your password"
                  />
                </Form.Item>
                <Form.Item className="margin-2">
                  <div
                    className="forgot-password-style"
                    onClick={handleForgotPassword}
                  >
                    {FORGOT_PASSWORD}
                  </div>
                </Form.Item>

                {hasErrored && (
                  <div className="error-message error-pad">{errorMessage}</div>
                )}

                <Form.Item>
                  <Button
                    type="primary"
                    className="height-50  full-width form_btn"
                    htmlType="submit"
                  >
                    SIGN IN
                  </Button>
                </Form.Item>
              </Form>
              <div className="text-left">
                <h4>
                  Don't have an account?
                  <span
                    onClick={handleSignUp}
                    className="forgot-password-style"
                  >
                    Create an Account
                  </span>
                </h4>
              </div>
            </div>
            {/* <div className="footer_form">
              <p>© {moment().year()} RobotMaker.AI. All Rights Reserved</p>
              <div className="">
                <p
                  onClick={() => {
                    setOpenPrModal(true);
                  }}
                >
                  Terms of Use
                </p>
                <p>Privacy Policy</p>
              </div>
            </div> */}
            <TermsAndPrivacy />
          </div>
        </>
      )}
      {/* {openPrModal && (
        <TermsAndPrivacy
          openModal={openPrModal}
          onCloseModal={onOnClosePrModal}
        />
      )} */}
      <Modal
        title="Please select a plan"
        visible={paymentPopUp}
        closable={false}
        bodyStyle={{ color: THEME_COLOR }}
        footer={[
          <Button key="submit" type="primary" onClick={handleProceed}>
            {PROCEED}
          </Button>,
        ]}
      >
        {paymentPopUpContent}
      </Modal>
      <Modal
        title="Verify your E-mail Address"
        visible={modalvisible}
        bodyStyle={{ color: THEME_COLOR }}
        closable={false}
        // onCancel={() => modaleclos()}
        footer={[
          <Button
            key="cancel"
            className="btns b-5"
            type="default"
            onClick={() => modaleclos()}
          >
            {CANCEL}
          </Button>,
          <Button
            key="submit"
            className="b-5 btns"
            type="primary"
            onClick={verifyemail}
          >
            {VERIFY_EMAIL}
          </Button>,
        ]}
      >
        <div className=" text-center">
          <h3 style={{ color: "#57387d" }}>
            Your code has been sent to{" "}
            <span style={{ fontWeight: 600 }}>{loginData?.email}</span>{" "}
          </h3>
          {/* {codeseterror ? <span style={{ color: 'red' }}>Please enter verification code</span> : ''} */}
          {resenderror ? <p style={{ color: "red" }}>{resenderror}</p> : ""}

          <input
            value={code}
            onChange={(e) => setcode(e.target.value)}
            className={
              codeseterror && code?.length === 0
                ? "login_input full-width inputBox-style height-50 b-5"
                : "full-width inputBox-style height-50 b-5"
            }
            style={{ padding: "0 10px" }}
            placeholder="Enter your code here"
          />

          {apiloader ? (
            <p style={{ margin: "10px 0 0" }}>Loading...</p>
          ) : (
            <p className="resend_code" onClick={handleResendCode}>
              Resend code
            </p>
          )}
        </div>
      </Modal>
    </div>
  );
}

const mapDispatchToProps = {
  getUser: getUser,
  getQuestions: getQuestions,
  getPlans: getPlans,
  resendCode: resendCode,
  verifyCode: verifyCode,
};

const mapStateToProps = ({
  userReducer: { apiloader },
  questionReducer: { questions, fetchingQuestions },
  workspaceReducer: { workspaceList },
}) => ({
  questions,
  fetchingQuestions,
  workspaceList,
  apiloader,
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
