import "./layout.css";

import { MOBILE_CONTENT, MOBILE_HEADER } from "../../constants/messages";
import { Modal, Spin } from "antd";
import React, { useEffect, useLayoutEffect, useState, useRef } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { ifAccessTokenExpired, logOutUser } from "../../actions/commonActions";
import AiForcast from "../../components/AiForcast/AiForcast";
import ForgotPassword from "../forgotPassword/forgotPassword";
import Login from "../../components/login/login";
import Navbar from "../../components/nav/navbar";
import PaymentMethod from "../../components/payment/paymentMethod";
import PaymentOptions from "../../components/payment/paymentOptions";
import FullInfo from "../../components/Backtest/FullInfo";
import Profile from "../../components/profile/profile";
import PropTypes from "prop-types";
import SignUp from "../../components/signUp/signUp";
import WorkspaceWindow from "../workspaceWindow/workspaceWindow";
// import RobotStore from "../robot_Store/robotStore";
import RobotStoreTab from "../robot_Store/RobotStoreTab";
import RobotRent from "../robot_Rent/robotRent";
import RobotEdit from "../robot_Rent/editRobot";
import RobotSubscription from "../robot_Store/robotSubscription";
import PaymentRobotSubscription from "../../components/payment/paymentSubscription";
import MySubRobot from "../mySubscribeRobot/mySubRobot";
import { connect } from "react-redux";
import { ConfigeUI } from "../../configeUI/ConfigeUI";
import Sidebar from "../sidebar/Sidebar";
import TradingAcc from "../TradingAccount/TradingAcc";
import CreateAcc from "../TradingAccount/CreateAcc";
import RobotDashboard from "../RobotDasboard/RobotDashboard";
import Backtest from "../Backtest/Backtest";
import BacktestResult from "../BacktestResult/BacktestResult";

/*
 * function to redirect to dashboard if the user is logged in
 * and tries to open login or other pages where log in is not required
 */
const AfterLogin = ({ component: Component, isLoggedIn, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        isLoggedIn ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{ pathname: "/login", state: { from: props.location } }}
          />
          
        )
      }
    />
  );
};
AfterLogin.propTypes = {
  component: PropTypes.any,
  isLoggedIn: PropTypes.string,
  location: PropTypes.object,
};

/*
 * function to redirect to login if the user is not logged in
 * and tries to open dashboard or other pages where log in is required
 */
const BeforeLogin = ({ component: Component, isLoggedIn, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        isLoggedIn ? (
          <Redirect
            to={{ pathname: "/workspace", state: { from: props.location } }}
          />
          
        ) : (
          <Component {...props} />
        )
      }
    />
  );
};
BeforeLogin.propTypes = {
  component: PropTypes.any,
  isLoggedIn: PropTypes.string,
  location: PropTypes.object,
};

/**
 * function to handle different page rendering based on pathname
 * @param {*} userData
 */
function StyledComp(props) {
  const isLoggedIn =  props.userData && (props.userData.active_status || props.userData.user?.active_status);
  const idlTimeRef = useRef(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const onIdle = () => {
    console.log("User is idle for secs now!");
    setIsModalOpen(false);
  };

  return (
    <div>
      <div className="flex flex-row layoutContainer">
        <div className="mainContentContainer">
          {isLoggedIn && <Route path="/" component={Navbar} />}
          <div className="body-toggle">
            {isLoggedIn &&  <Route path="/" component={Sidebar} />}
            <div className="contentBody">
              {isLoggedIn ? (
                <Switch>
                  <AfterLogin
                    path="/ai-forcast"
                    exact
                    isLoggedIn={isLoggedIn}
                    component={AiForcast}
                  />
                  <AfterLogin
                    path="/workspace"
                    exact
                    isLoggedIn={isLoggedIn}
                    component={WorkspaceWindow}
                  />
                  <AfterLogin
                    path="/profile"
                    exact
                    isLoggedIn={isLoggedIn}
                    component={Profile}
                  />
                  <AfterLogin
                    path="/create_acc"
                    exact
                    isLoggedIn={isLoggedIn}
                    component={CreateAcc}
                  />
                  <AfterLogin
                    path="/trading_account"
                    exact
                    isLoggedIn={isLoggedIn}
                    component={TradingAcc}
                  />
                  {/* <AfterLogin path="/robot_store" exact isLoggedIn={isLoggedIn} component={RobotStore} /> */}
                  <AfterLogin
                    path="/robot_store"
                    exact
                    isLoggedIn={isLoggedIn}
                    component={RobotStoreTab}
                  />
                  <AfterLogin
                    path="/robot_rent"
                    exact
                    isLoggedIn={isLoggedIn}
                    component={RobotRent}
                  />
                  <AfterLogin
                    path="/full_info"
                    exact
                    isLoggedIn={isLoggedIn}
                    component={FullInfo}
                  />
                  <AfterLogin
                    path={`/robot_rent/:id`}
                    exact
                    isLoggedIn={isLoggedIn}
                    component={RobotEdit}
                  />
                  <AfterLogin
                    path={`/robot_store/:id`}
                    exact
                    isLoggedIn={isLoggedIn}
                    component={RobotSubscription}
                  />
                  <AfterLogin
                    path={`/my_robot`}
                    exact
                    isLoggedIn={isLoggedIn}
                    component={MySubRobot}
                  />
                  <AfterLogin
                    path={`/robot-dashboard`}
                    exact
                    isLoggedIn={isLoggedIn}
                    component={RobotDashboard}
                  />
                  <AfterLogin
                    path={`/payment-robot/:id`}
                    exact
                    isLoggedIn={isLoggedIn}
                    component={PaymentRobotSubscription}
                  />
                  <AfterLogin
                    path={`/backtest`}
                    exact
                    isLoggedIn={isLoggedIn}
                    component={Backtest}
                  />
                  <AfterLogin
                    path={`/backtest-result`}
                    exact
                    isLoggedIn={isLoggedIn}
                    component={BacktestResult}
                  />
                  <Route
                    render={() => (
                      <Redirect
                        to={{
                          pathname: "/workspace",
                          state: { from: props.location },
                        }}
                      />
                    )}
                  />
                </Switch>
              ) : (
                !localStorage.getItem("token") && (
                  <Switch>
                    <BeforeLogin
                      path="/"
                      exact
                      isLoggedIn={isLoggedIn}
                      component={Login}
                    />
                    <BeforeLogin
                      path="/login"
                      isLoggedIn={isLoggedIn}
                      component={Login}
                    />
                    <BeforeLogin
                      path="/sign-up"
                      isLoggedIn={isLoggedIn}
                      component={SignUp}
                    />
                    <BeforeLogin
                      path="/forgot-password"
                      exact
                      isLoggedIn={isLoggedIn}
                      component={ForgotPassword}
                    />
                    <BeforeLogin
                      path="/payment"
                      exact
                      isLoggedIn={isLoggedIn}
                      component={PaymentOptions}
                    />
                    <BeforeLogin
                      path="/payment-method"
                      exact
                      isLoggedIn={isLoggedIn}
                      component={PaymentMethod}
                    />
                    <Route
                      render={() => (
                        <Redirect
                          to={{
                            pathname: "/login",
                            state: { from: props.location },
                          }}
                        />
                      )}
                    />
                  </Switch>
                )
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
StyledComp.propTypes = {
  userData: PropTypes.object,
  location: PropTypes.object,
};

function LayoutComponent(props) {
  const [mobileWarning, setMobileWarning] = useState(false);

  // useEffect(() => {
  //   const storedDate = localStorage.getItem("lastActiveDate");
  //   const token = localStorage.getItem("token");
  //   const checkForDateChange = () => {
  //     const currentDate = new Date();
  //     const storedDateObj = new Date(storedDate);

  //     // Calculate the difference in seconds between the current date and the stored date
  //     const timeDifference = (currentDate - storedDateObj) / 1000;

  //     if (timeDifference >= 3600) {
  //       localStorage.clear();
  //       window.location.replace("/login");
  //     }
  //   };

  //   if (storedDate) {
  //     setInterval(() => {
  //       // checkForDateChange();
  //       // ifAccessTokenExpired(token);
  //     }, 6000);
  //   }
  // }, []);

  useLayoutEffect(() => {
    function updateSize() {
      setMobileWarning(window.innerWidth < 999);
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);

  useEffect(() => {
    ConfigeUI();
  }, []);
  const {
    fetchingUser,
    fetchingQuestions,
    userData,
    fetchingPayment,
    fetchingWorkspace,
    fetchingfileWorkspace,
    fetchingAllRobot,
    fetchingDashboard
  } = props;
  //conditions for rendering loader true
  let isFetching =
    fetchingUser ||
    fetchingQuestions ||
    fetchingPayment ||
    fetchingWorkspace ||
    fetchingfileWorkspace ||
    fetchingDashboard ||
    fetchingAllRobot;

  return (
    <>
      <Spin
        tip="Loading..."
        size="large"
        spinning={isFetching}
        className="spinner"
      >
        <StyledComp userData={userData} />
      </Spin>
      <Modal
        title={MOBILE_HEADER}
        visible={mobileWarning}
        footer={null}
        closable={false}
      >
        <p>{MOBILE_CONTENT}</p>
      </Modal>
    </>
  );
}

const mapStateToProps = ({
  userReducer: { userData, accessToken, fetchingUser },
  questionReducer: { fetchingQuestions },
  paymentReducer: { fetchingPayment },
  workspaceReducer: { fetchingWorkspace, fetchingfileWorkspace },
  robotStoreReducer: { fetchingAllRobot },
  dashboardReducer: { fetchingDashboard },
}) => ({
  userData,
  accessToken,
  fetchingUser,
  fetchingQuestions,
  fetchingPayment,
  fetchingWorkspace,
  fetchingfileWorkspace,
  fetchingAllRobot,
  fetchingDashboard
});

const mapDispatchToProps = {
  logOutUser: logOutUser,
};

export default connect(mapStateToProps, mapDispatchToProps)(LayoutComponent);
