import { Modal } from "antd";
import moment from "moment";
import React, { useState } from "react";
import "./TermsAndPrivacy.css";

const TermsAndPrivacy = () => {
  // const []
  const [TermsModal, setTermsModal] = useState(false);
  const [PrivacyModal, setPrivacyModal] = useState(false);
  return (
    <>
      <div className="footer_form">
        <p>© {moment().year()} RobotMaker.AI. All Rights Reserved</p>
        <div className="">
          <p
            className="terms"
            onClick={() => {
              setTermsModal(true);
            }}
          >
            Terms of Use
          </p>
          <p
            className="privacy"
            onClick={() => {
              setPrivacyModal(true);
            }}
          >
            Privacy Policy
          </p>
        </div>
      </div>
      {TermsModal && (
        <Modal
          title={"Terms of Use"}
          visible={TermsModal}
          footer={null}
          onCancel={() => setTermsModal()}
          className="custom-modal"
        >
          <div className="modal-content">
            <p>
              Welcome to <span className="title">Robot Maker</span>. These Terms
              of Use govern your access for the usage of our website,
              marketplace, and AI bot creation tools for forex trading. By using
              our services, you agree to be bound by these Terms. If you do not
              agree, please do not use our platform.
            </p>
            <br />
            <div>
              <span className="title">Eligibility</span>
              <p>
                You must be at least 18 years old to use our services. You
                confirm that you are legally permitted to engage in trading in
                your jurisdiction. If you are accessing our platform from
                outside the UK, you are responsible for ensuring compliance with
                your local laws.
              </p>
            </div>
            <br />
            <div>
              <span className="title">UK Regulatory Compliance</span>
              <p>
                We do not provide financial, investment, or trading advice.
                <br />
                We do not operate as a financial services firm and are not
                regulated by the UK Financial Conduct Authority (FCA). You are
                responsible for ensuring that your use of our platform complies
                with applicable UK financial regulations.
                <br />
                Users in the UK must comply with HMRC tax obligations regarding
                forex trading gains and losses. We do not provide tax advice.
              </p>
            </div>
          </div>
        </Modal>
      )}
      {PrivacyModal && (
        <Modal
          title={"Privacy Policy"}
          visible={PrivacyModal}
          footer={null}
          onCancel={() => setPrivacyModal(false)}
        >
          <div className="modal-content">
            <p>
              Welcome to <span className="title">Robot Maker</span>. We respect
              your privacy and are committed to safeguarding your personal
              information. This Privacy Policy outlines how we collect, use, and
              protect the data you provide while using our website and services.
            </p>
            <br />
            <div>
              <span className="title">Information We Collect</span>
              <p>
                When you use our platform, we may collect various types of
                personal information including your name, email address, and
                payment details. We may also collect non-personal data such as
                your browser type, device information, and IP address to enhance
                your experience and improve our services.
              </p>
            </div>
            <br />
            <div>
              <span className="title">How We Use Your Data</span>
              <p>
                Your data is primarily used to:
                <ul>
                  <li>Provide you with access to our platform and services</li>
                  <li>Offer support and respond to your queries</li>
                  <li>Process your payments and manage your account</li>
                  <li>
                    Send relevant updates and notifications regarding new
                    features or changes
                  </li>
                  <li>
                    Improve the functionality of our services and ensure a
                    secure platform
                  </li>
                </ul>
              </p>
            </div>
            <br />
            <div>
              <span className="title">Third-Party Sharing</span>
              <p>
                We do not share your personal information with third parties for
                marketing purposes. However, we may share certain data with
                trusted third-party service providers who assist in operating
                our platform. These third parties are obligated to handle your
                data with strict confidentiality and only for the purpose of
                providing their services to us.
              </p>
            </div>
            <br />
            <div>
              <span className="title">Your Data Security</span>
              <p>
                We employ industry-standard security measures to protect your
                personal information from unauthorized access, disclosure,
                alteration, or destruction. While we strive to secure your data,
                no system can be completely secure, and we cannot guarantee the
                absolute safety of your information.
              </p>
            </div>
            <br />
            <div>
              <span className="title">Your Rights and Choices</span>
              <p>
                You have the right to access, update, or delete your personal
                information at any time. If you would like to exercise these
                rights, please contact us directly. Additionally, you can
                unsubscribe from any marketing communications we send you by
                following the unsubscribe link provided in each email.
              </p>
            </div>
            <br />
            <div>
              <span className="title">Policy Updates</span>
              <p>
                We may update this Privacy Policy from time to time. Any changes
                will be posted on this page, and we encourage you to review it
                periodically to stay informed about how we protect your
                information.
              </p>
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};

export default TermsAndPrivacy;
