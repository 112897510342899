import "./signUp.css";

import {
  ANSWER_REQUIRED,
  CONFIRM_PASSWORD,
  EMAIL_REQUIRED,
  INVALID_PASSWORD,
  LOGIN,
  NAME_REQUIRED,
  PASSWORD_DO_NOT_MATCH,
  PASSWORD_INFO,
  PASSWORD_REQUIRED,
  SIGN_UP,
  SIGN_UP_HEADER,
  ERROR,
  THEME_COLOR,
  USER_EXISTS,
  USERNAME_REQUIRED,
  SUCCESS,
} from "../../constants/messages";
import { Button, Form, Input, message, Modal, Select } from "antd";
import {
  EMAIL_VALIDATION,
  PASSWORD_VALIDATION,
} from "../../constants/constants";
import React, { useEffect, useState } from "react";
import logo from "../../assets/robotasset/logo.png";
import PaymentMethod from "../payment/paymentMethod";
import PaymentOptions from "../payment/paymentOptions";
import SideBanner from "../commonComponent/sideBanner";
import { connect } from "react-redux";
import { getPlans } from "../../actions/paymentActions";
import { postUser, verifyUser } from "../../actions/commonActions";
import UserIcon from "../../assets/robotasset/usericon.png";
import EmailIcon from "../../assets/robotasset/email.png";
import PassIcon from "../../assets/robotasset/Password.png";
import moment from "moment";
import TermsAndPrivacy from "../Terms&Privacy";

const IMF = ({ url }) => {
  return (
    <img src={url} style={{ height: "25px", marginRight: "5px" }} alt="" />
  );
};

function SignUp(props) {
  const [hasErrored, setHasErrored] = useState(false);
  const [passwordPattern, setPasswordPattern] = useState("");
  const [errorMessage, setErrorMessage] = useState("Wrong Username/Password");
  const [submitData, setSubmitData] = useState({});
  const [showPayment, setShowPayment] = useState(false);
  const [showPaymentOptions, setShowPaymentOptions] = useState(false);
  const [selectedType, setSelectedType] = useState("");
  const [selectedPrice, setSelectedPrice] = useState("");
  const [visible, setVisible] = useState(false);
  const [modalvisible, setmodalVisible] = useState(false);
  const { verifyUser } = props;

  const handlePasswordChange = (input) => {
    let value = input.target.value;
    value = "^" + value + "$";
    setPasswordPattern(value);
    setHasErrored(false);
  };

  const onSubmit = (type, price, couponCode) => {
    setSelectedPrice(price);
    setSelectedType(type);
    const data =
      couponCode === ""
        ? {
            ...submitData,
            subscription_plan: type,
          }
        : {
            ...submitData,
            subscription_plan: type,
            coupon_code: couponCode,
          };

    props.postUser({
      data: { ...data },
      callback: (resp, data) => {
        if (resp !== ERROR) {
          message.success(data.message);
          setmodalVisible(true);
        } else if (resp === ERROR) {
          message.error(data);
          setShowPaymentOptions(true);
          setShowPayment(false);
        } else {
          setVisible(true);
          setErrorMessage(data.message);
          setHasErrored(true);
        }
      },
    });
  };

  const onFinish = (values) => {
    const data = { ...values, email: values.email.toLowerCase().trim() };

    setSubmitData({ ...data });
    verifyUser({
      data: { ...data },
      callback: (resp, data) => {
        if (resp === ERROR) {
          message.error(data);
        } else if (resp === SUCCESS) {
          setShowPayment(true);
        } else {
          message.error("try again");
        }
      },
    });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const handleLogin = () => {
    props.history.push("/login");
  };

  const handleChange = () => {
    if (hasErrored) setHasErrored(false);
  };

  return (
    <div className="signup-container">
      <SideBanner />
      {showPayment && !showPaymentOptions ? (
        <PaymentOptions
          onSubmit={onSubmit}
          history={props.history}
          paymentPopUp={false}
          modalvisible={modalvisible}
          setShowPaymentOptions={setShowPaymentOptions}
          setmodalVisible={setmodalVisible}
          submitData={submitData}
          selectedType={selectedType}
        />
      ) : showPayment && showPaymentOptions ? (
        <PaymentMethod
          history={props.history}
          loginData={submitData}
          selectedPrice={selectedPrice}
          selectedType={selectedType}
        />
      ) : (
        <div className="right-body-login center_div">
          <img
            src={require("../../assets/robotasset/logo.png")}
            className="auth_logo"
            alt=""
          />
          <div className="login-box">
            <Form
              name="basic"
              initialValues={{
                remember: true,
              }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
            >
              <Form.Item className="login_title">
                <div className="large-text label theme-text text-left ">
                  Create an account!
                </div>
                <p>Sign up and get your automated trading account!</p>
              </Form.Item>
              <Form.Item>
                <div className="name-container height-50 full-width">
                  <div className="d-flex-colum" style={{ flex: 1 }}>
                    <div className="f_label">First Name*</div>
                    <Form.Item
                      className="full-width"
                      name="first_name"
                      rules={[
                        {
                          required: true,
                          message: NAME_REQUIRED,
                        },
                      ]}
                    >
                      <Input
                        prefix={<IMF url={UserIcon} />}
                        onChange={handleChange}
                        className="full-width inputBox-style height-50 b-5"
                        placeholder="Enter first name"
                      />
                    </Form.Item>
                  </div>
                  <div className="d-flex-colum" style={{ flex: 1 }}>
                    <div className="f_label">Last Name*</div>
                    <Form.Item name="last_name" className="full-width">
                      <Input
                        onChange={handleChange}
                        prefix={<IMF url={UserIcon} />}
                        className="full-width inputBox-style height-50 b-5 "
                        placeholder="Enter last Name"
                      />
                    </Form.Item>
                  </div>
                </div>
              </Form.Item>
              <div className="d-flex-colum">
                <div className="f_label" style={{ marginTop: "15px" }}>
                  EMAIL*
                </div>
                <Form.Item
                  name="email"
                  rules={[
                    { required: true, message: EMAIL_REQUIRED },
                    {
                      pattern: EMAIL_VALIDATION,
                      message: EMAIL_REQUIRED,
                    },
                  ]}
                >
                  <Input
                    prefix={<IMF url={EmailIcon} />}
                    onChange={handleChange}
                    className="inputBox-style height-50 b-5"
                    placeholder="Email"
                  />
                </Form.Item>
              </div>
              <div className="d-flex-colum">
                <div className="f_label">Create Password*</div>
                <Form.Item
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: PASSWORD_REQUIRED,
                    },
                    {
                      pattern: PASSWORD_VALIDATION,
                      message: INVALID_PASSWORD,
                    },
                  ]}
                >
                  <Input.Password
                    prefix={<IMF url={PassIcon} />}
                    onChange={handlePasswordChange}
                    className="inputBox-style height-50 b-5"
                    placeholder="Enter your Password"
                  />
                </Form.Item>
              </div>
              <div className="d-flex-colum">
                <div className="f_label">Repeat Password*</div>
                <Form.Item
                  name="confirm password"
                  rules={[
                    { required: true, message: CONFIRM_PASSWORD },
                    {
                      pattern: passwordPattern,
                      message: PASSWORD_DO_NOT_MATCH,
                    },
                  ]}
                >
                  <Input.Password
                    prefix={<IMF url={PassIcon} />}
                    onChange={handleChange}
                    className="inputBox-style height-50 b-5"
                    placeholder="Enter Repeat Password"
                  />
                </Form.Item>
              </div>
              <div className="password-info">{PASSWORD_INFO}</div>
              {hasErrored && (
                <div className="error-message padding-20">{errorMessage}</div>
              )}
              <Form.Item>
                <Button
                  type="primary"
                  className="button height-50 primary-button full-width b-5 login-btn"
                  htmlType="submit"
                  style={{ marginTop: "15px" }}
                >
                  {SIGN_UP}
                </Button>
              </Form.Item>
            </Form>
            <div className="">
              <h4>
                Already have an account ?{" "}
                <span onClick={handleLogin} className="forgot-password-style">
                  SIGN IN
                </span>
              </h4>
            </div>
          </div>
          {/* <div className="footer_form">
            <p>© {moment().year()} RobotMaker.AI. All Rights Reserved</p>
            <div className="">
              <p>Terms of Use</p>
              <p>Privacy Policy</p>
            </div>
          </div> */}
          <TermsAndPrivacy />
        </div>
      )}
      <Modal
        title="Account Already Exists!"
        visible={visible}
        closable={false}
        bodyStyle={{ color: THEME_COLOR }}
        footer={[
          <Button
            key="submit"
            className="b-5"
            type="primary"
            onClick={handleLogin}
          >
            {LOGIN}
          </Button>,
        ]}
      >
        <p>{USER_EXISTS}</p>
      </Modal>
    </div>
  );
}

const mapStateToProps = ({
  userReducer: { fetchingUser },
  paymentReducer: { plans },
}) => ({
  fetchingUser,
  plans,
});

const mapDispatchToProps = {
  postUser: postUser,
  getPlans: getPlans,
  verifyUser: verifyUser,
};

export default connect(mapStateToProps, mapDispatchToProps)(SignUp);
